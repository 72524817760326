import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Iframe from "react-iframe"
import { Helmet } from "react-helmet"

import Layout from "../../components/common/layout/layout"
import SEO from "../../components/common/layout/seo"
import Navigation from "../../components/common/navigation/navigation"
import Footer from "../../components/sections/footer"

import skilling from "../../images/icons/Icon/Skilling.png"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Popup from "../../components/Popup"
import { SectionDivider, HalfColumn, TwoColumn } from "../../components/global"
import { render } from "react-dom"
import parse from "html-react-parser"
import { useLocation } from "@reach/router"
import queryString from "query-string"

const query = graphql`
  query {
    allStrapiBlog(sort: { fields: Date, order: DESC }) {
      edges {
        node {
          id
          Title
          Blog_description
          Date(formatString: "MMMM DD, YYYY")
          Main_content
          Image {
            childImageSharp {
              fixed(width: 800) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`
const GetBlogID = () => {
  var querystring = useLocation()
  var blog_id = querystring.search.substring(4)
  return blog_id
}

const Blog = () => (
  <Layout>
    
    <Navigation></Navigation>
    <CategoryContainer>
      <StaticQuery
        query={query}
        render={(data) => (
          <StoreC>
            {data.allStrapiBlog.edges
              .filter((blog) => blog.node.id === GetBlogID())
              .map((blog) => (
                <Store>
                  <Helmet>
                    <title>{parse(blog.node.Title)+' - Blogs By Dili'}</title>
                    <meta
                    name="description"
                    content="Pay through a Dili no-cost EMI and signup for career/skill growth courses like AI & Machine learning, Digital Marketing, Part-time MBA and more."
                    />
                    <script src="/Jslibrary.js" defer></script>
                  </Helmet>
                  <StoreHeader>{parse(blog.node.Title)}</StoreHeader>
                  <ImgContainer>
                    <StoreImg fixed={blog.node.Image.childImageSharp.fixed} />
                  </ImgContainer>
                  <StoreText id={"StoreHeader_" + blog.node.id}>
                    {parse(blog.node.Main_content)}
                  </StoreText>
                  <StoreButtonContainer>
                    <Link to="/blogs/blogs/">
                      <StoreButton>View All Blogs</StoreButton>
                    </Link>
                    <ShareThisArticleText>
                      Share this article.
                    </ShareThisArticleText>
                    <p></p>
                    <div data-color="official" data-icons-type="custom:solid">
                      <a
                        href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fblog.dilinow.com%2Ftravel-now-pay-later%2F"
                        data-network="facebook"
                        aria-label="Facebook"
                        // style="--official-color: #557dbc"
                        rel="noopener noreferrer nofollow"
                      >
                        <FacebookContainer>
                          <svg
                            fill="white"
                            width="20px"
                            height="20px"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                          >
                            <path d="M20,10.1c0-5.5-4.5-10-10-10S0,4.5,0,10.1c0,5,3.7,9.1,8.4,9.9v-7H5.9v-2.9h2.5V7.9C8.4,5.4,9.9,4,12.2,4c1.1,0,2.2,0.2,2.2,0.2v2.5h-1.3c-1.2,0-1.6,0.8-1.6,1.6v1.9h2.8L13.9,13h-2.3v7C16.3,19.2,20,15.1,20,10.1z"></path>
                          </svg>
                        </FacebookContainer>
                      </a>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <a
                        href="https://twitter.com/share?url=https%3A%2F%2Fblog.dilinow.com%2Ftravel-now-pay-later%2F&amp;text=It%20is%20time%20to%20travel%20with%20an%20open%20heart%20without%20worrying%20about%20the%20expenses"
                        data-network="twitter"
                        aria-label="Twitter"
                        // style="--official-color: #7acdee"
                        rel="noopener noreferrer nofollow"
                      >
                        <TwitterContainer>
                          <svg
                            fill="white"
                            width="20px"
                            height="20px"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                          >
                            <path d="M20,3.8c-0.7,0.3-1.5,0.5-2.4,0.6c0.8-0.5,1.5-1.3,1.8-2.3c-0.8,0.5-1.7,0.8-2.6,1c-0.7-0.8-1.8-1.3-3-1.3c-2.3,0-4.1,1.8-4.1,4.1c0,0.3,0,0.6,0.1,0.9C6.4,6.7,3.4,5.1,1.4,2.6C1,3.2,0.8,3.9,0.8,4.7c0,1.4,0.7,2.7,1.8,3.4C2,8.1,1.4,7.9,0.8,7.6c0,0,0,0,0,0.1c0,2,1.4,3.6,3.3,4c-0.3,0.1-0.7,0.1-1.1,0.1c-0.3,0-0.5,0-0.8-0.1c0.5,1.6,2,2.8,3.8,2.8c-1.4,1.1-3.2,1.8-5.1,1.8c-0.3,0-0.7,0-1-0.1c1.8,1.2,4,1.8,6.3,1.8c7.5,0,11.7-6.3,11.7-11.7c0-0.2,0-0.4,0-0.5C18.8,5.3,19.4,4.6,20,3.8z"></path>
                          </svg>
                        </TwitterContainer>
                      </a>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <a
                        href="https://www.linkedin.com/shareArticle?url=https%3A%2F%2Fblog.dilinow.com%2Ftravel-now-pay-later%2F&amp;title=It%20is%20time%20to%20travel%20with%20an%20open%20heart%20without%20worrying%20about%20the%20expenses"
                        data-network="linkedin"
                        aria-label="LinkedIn"
                        // style="--official-color: #1c86c6"
                        rel="noopener noreferrer nofollow"
                      >
                        <LinkedInContainer>
                          <svg
                            fill="white"
                            width="20px"
                            height="20px"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                          >
                            <path d="M18.6,0H1.4C0.6,0,0,0.6,0,1.4v17.1C0,19.4,0.6,20,1.4,20h17.1c0.8,0,1.4-0.6,1.4-1.4V1.4C20,0.6,19.4,0,18.6,0z M6,17.1h-3V7.6h3L6,17.1L6,17.1zM4.6,6.3c-1,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7C6.3,5.5,5.5,6.3,4.6,6.3z M17.2,17.1h-3v-4.6c0-1.1,0-2.5-1.5-2.5c-1.5,0-1.8,1.2-1.8,2.5v4.7h-3V7.6h2.8v1.3h0c0.4-0.8,1.4-1.5,2.8-1.5c3,0,3.6,2,3.6,4.5V17.1z"></path>
                          </svg>
                        </LinkedInContainer>
                      </a>
                    </div>
                  </StoreButtonContainer>
                </Store>
              ))}
          </StoreC>
        )}
      />
    </CategoryContainer>
    <Footer />
  </Layout>
)

export default Blog

export const CategoryContainer = styled.div`
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 140px;
  border: 0px solid blue;
`

export const CategoryHeader = styled.div`
  color: #142630;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: -0.02em;
  margin-right: 40px;
`

export const CategoryText = styled.div`
  color: #465f6d;
  font-size: 20px;
  letter-spacing: -0.02em;
  line-height: 1.5;
  margin-top: 20px;
  width: 100%;
  text-align: justify;
`

export const Icon = styled.img`
  width: 80px;
  height: 80px;
  margin-bottom: 32px;
`

export const Store = styled.div`
  left: 50%;
  width: 100%;
  height: 100%;
  margin: 0px;
  margin-bottom: 24px;
  border: 0px solid brown;
`

export const ImgContainer = styled.div`
  // height: 100px;
  border: 0px solid #dcdcdc;
  border-radius: 5px;
  position: relative;
`

export const StoreImg = styled(Img)`
  margin: 0;
  position: absolute;
  top: 0%;
  left: 50%;
  max-width: 100%;
  max-height: 100%;
  -ms-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
`

export const StoreHeader = styled.div`
  font-family: "Arimo", sans-serif !important;
  font-size: 30px;
  margin-bottom: 10px;
  margin-top: 8px;
  font-weight: bold;
  width: 800px;
  text-align: center;
  border: 0px solid red;
  margin-left: auto;
  margin-right: auto;
`
export const StoreTextContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 0px solid #e6e6e6;
  margin-bottom: 10px;
  background-color: #ffffff;
  margin-left: auto;
  margin-right: auto;
`

export const StoreText = styled.div`
  font-family: "DM Sans", sans-serif !important;
  font-size: 14px;
  padding: 5px;
  width: 800px;
  height: 100%;
  line-height: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  text-align: justify;
  border: 0px solid red;
`

export const ReadMoreText = styled.div`
  font-size: 13px;
  width: 100%;
  padding-right: 5px;
  text-align: right;
`

export const StoreButtonContainer = styled.div`
  width: 100%;
  border: 0px solid red;
  text-align: center;
`

export const StoreButton = styled.button`
  background: #e88d15;
  color: white;
  font-weight: bold;
  font-size: 14px;
  width: 300px;
  padding: 6px;
  border-radius: 40px;
  margin-left: auto;
  margin-right: auto;
`
export const FacebookContainer = styled.span`
  background-color: #557dbc;
  padding: 10px 25px;
  border-radius: 2px;
`
export const TwitterContainer = styled.span`
  background-color: #7acdee;
  padding: 10px 25px;
  border-radius: 2px;
`
export const LinkedInContainer = styled.span`
  background-color: #1c86c6;
  padding: 10px 25px;
  border-radius: 2px;
`

export const ShareThisArticleText = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: 40px;
`

const FormText = styled.div`
  flex-direction: column;
  margin-top: 0px;
`

const FormHeader = styled.div`
  font-size: 36px;
  font-weight: bold;
  color: #142630;
  width: 60%;

  @media (max-width: ${(props) => props.theme.screen.md}) {
  }

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    font-size: 40px;
    width: 80%;
  }

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    font-size: 24px;
    width: 80%;
  }
`

const FormSubheader = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  text-align: justify;
  font-size: 20px;
  color: #465f6d;
  line-height: 1.5;

  @media (max-width: ${(props) => props.theme.screen.md}) {
  }

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 480px;
  }

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    width: 360px;
    font-size: 14px;
  }
`

const FormContainer = styled.div`
  display: flex;
  padding-top: 24px;
  padding-bottom: 80px;
`

const FormEmbed = styled(Iframe)`
  width: 640px;
  height: 640px;
`

export const StoreC = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 0px solid green;
  justify-content: justify;
`
export const BackLink = styled(Link)`
  margin-bottom: 80px;
`
